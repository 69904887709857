<template>
  <div>
    <div v-if="data">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                <h3>Status Code</h3>
              </th>
              <th class="text-left">
                <h3>Sample Response</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="response in Object.keys(data)" :key="response">
              <td>{{ response }}</td>
              <td>
                <code class="elevation-0">{{data[response]}}</code>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- <p>{{JSON.stringify(data)}}</p> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["name", "data"],
  data() {
    return {};
  },
  methods: {},
  watch: {}
};
</script>