<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Name</th>
          <th class="text-left">Type</th>
          <th class="text-left">Description</th>
          <th class="text-left">Required</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(prop, index) in Object.keys(data.properties)" v-bind:key="prop">
          <td>{{prop}}</td>
          <td>{{data.properties[Object.keys(data.properties)[index]].type }}</td>
          <td>{{data.properties[Object.keys(data.properties)[index]].description }}</td>
          <td v-if="data.required" width="50">
            <v-chip color="warning" v-if="data.required.includes(prop)">
              <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
            </v-chip>
            <v-chip v-else color="disabled">
              <v-icon dark right>mdi-close</v-icon>
            </v-chip>
          </td>
          <td v-else width="50">
            <v-chip color="disabled">
              <v-icon dark right>mdi-close</v-icon>
            </v-chip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
export default {
  props: ["name", "data"],
  data() {
    return {};
  },
  methods: {},
  watch: {}
};
</script>