<template>
  <div class="Documentation overflow-y-auto" id="scroll-target">
    <!-- <v-tabs background-color="white" elevation="1" class="fixed-tabs-bar">
      <v-tab to="/documentation/devices">Devices</v-tab>
      <v-tab to="/documentation/products">Products</v-tab>
      <v-tab to="/documentation/stats">Scans</v-tab>
      <v-tab to="/documentation/users">Emails</v-tab>
    </v-tabs>-->
    <!-- <v-breadcrumbs :items="adresses"></v-breadcrumbs> -->
    <!-- <v-container fluid>
      <router-view></router-view>
    </v-container>
    -->
    <v-row>
      <v-col
        cols="12"
        sm="7"
        style="width:100%; box-sizing:border-box; max-width:100%; margin:0; padding:0;"
      >
        <div style="padding-left:30px;">
          <v-spacer style="height:40px;"></v-spacer>

          <v-card style="margin:20px;">
            <v-card-text>
              <b>Hi,</b>
              <br />This is the Bige Api Documentation,
              <b>this api has been designed to manage the the Biges Users and articles</b>.
              <br />This is your current account authorization token :
              <br />
              <br />
              <code
                style="padding:10px;width:100%; min-width:300px; word-break:break-all;"
              >{{access}}</code>
            </v-card-text>
          </v-card>
        </div>
        <v-spacer style="height:80px;"></v-spacer>
      </v-col>
      <v-col cols="12" sm="5" class="code_column">
        <br />
        <h6>THIS IS A SAMPLE PRIVATE SERVER CREDENTIAL CONF FOR INSTALL</h6>
        <code style="font-size:10px; max-width:97%; word-break:break-all;">
          "FIREBASE": {
          "apiKey": "FIREBASE_API_KEY",
          "authDomain": "FIREBASE_PROJECT_AUTH_DOMAIN",
          "databaseURL": "FIREBASE_DATABASE_URL",
          "projectId": "FIREBASE_PROJECT_ID",
          "storageBucket": "FIREBASE_STORAGE_BUCKET",
          "messagingSenderId": "FIREBASE_SENDER_ID_FOR_GCM",
          "appId": "FIREBASE_APP_ID"
          },
          "FIREBASE_SERVER_CREDENTIAL": {
          "type": "service_account",
          "project_id": "FIREBASE_PROJECT_NAME",
          "private_key_id": "FIREBASE_PRIVATE_KEY_ID",
          "private_key": "FIREBASE_PRIVATE_KEY",
          "client_email": "FIREBASE_CLIENT_EMAIL",
          "client_id": "FIREBASE_CLIENT_ID",
          "auth_uri": "https://accounts.google.com/o/oauth2/auth",
          "token_uri": "https://oauth2.googleapis.com/token",
          "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
          "client_x509_cert_url": "FIREBASE_CLIENT_CERT"
          },
          "EMAIL": {
          "email": "your_email@domain.exten",
          "pwd": "PWD"
          }
          }
        </code>
        <v-spacer style="height:80px;"></v-spacer>
      </v-col>
    </v-row>

    <v-row
      v-for="(doc) in documentation"
      v-bind:key="doc.path+':'+doc.method"
      style="width:100%; box-sizing:border-box; max-width:100%; margin:0; padding:0;"
    >
      <v-col cols="12" sm="7">
        <v-divider></v-divider>
        <div>
          <v-row fluid>
            <v-col cols="4">
              <v-card-title class="route_title float-left">{{doc.path}}</v-card-title>
            </v-col>
            <v-col>
              <v-chip
                class="float-right ma-4"
                :color="getColor(doc.method)"
              >{{doc.method.toUpperCase()}}</v-chip>
              <span v-if="doc.setup" class="float-right">
                <v-chip
                  class="ma-4"
                  color="warning"
                  v-if="doc.setup.auth"
                >header authorization required</v-chip>
              </span>
            </v-col>
          </v-row>
        </div>
        <div v-if="doc.setup">
          <v-card-title>{{doc.setup.name}}</v-card-title>
          <v-card-text v-html="doc.setup.description"></v-card-text>
          <!-- HEADERS PARAMS SETUP -->
          <div v-if="doc.setup.headers">
            <v-divider></v-divider>
            <v-card-title>Headers params</v-card-title>
            <PropTable name="Headers Params from template" :data="doc.setup.headers"></PropTable>
          </div>
          <!-- QUERY PARAMS SETUP -->
          <div v-if="doc.setup.query">
            <v-divider></v-divider>
            <v-card-title>Query params</v-card-title>
            <PropTable name="Query Params from template" :data="doc.setup.query"></PropTable>
          </div>
          <!-- URI PARAMS SETUP -->
          <div v-if="doc.setup.params">
            <v-divider></v-divider>
            <v-card-title>URI params</v-card-title>
            <PropTable name="Uri Params from template" :data="doc.setup.params"></PropTable>
          </div>
          <!-- BODY PARAMS SETUP -->
          <div v-if="doc.setup.body">
            <v-divider></v-divider>
            <v-card-title>Body params</v-card-title>
            <PropTable name="Body Params from template" :data="doc.setup.body"></PropTable>
          </div>

          <v-col cols="12" v-if="doc.setup.tags">
            <v-row>
              <v-chip class="ma-1" v-for="tag in doc.setup.tags" :key="tag">{{tag}}</v-chip>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="doc.setup.scopes">
            <v-row>
              <v-chip
                class="ma-1"
                color="primary"
                v-for="scope in doc.setup.scopes"
                :key="scope"
              >{{scope}}</v-chip>
            </v-row>
          </v-col>
          <!-- <p>{{JSON.stringify(doc.setup)}}</p> -->
        </div>
        <v-spacer></v-spacer>
      </v-col>
      <v-col cols="12" sm="5" class="code_column">
        <v-spacer style="height:20px;"></v-spacer>
        <Responses v-if="doc.setup" name="responses" :data="doc.setup.responses"></Responses>
        <!-- <code dark v-if="doc.setup">{{doc.setup.responses}}</code> -->
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <!-- <p>{{JSON.stringify($store.state.doc)}}</p> -->
  </div>
</template>
<script>
import PropTable from "../components/Documentation/PropTable";
import Responses from "../components/Documentation/Responses";

export default {
  name: "Documentation",
  data() {
    return {
      adresses: [],
      offsetTop: 0
    };
  },
  components: {
    PropTable,
    Responses
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    access() {
      return this.$store.state.access;
    },
    documentation() {
      return this.$store.state.documentation;
    }
  },
  created() {
    if (!this.$store.doc) this.$store.dispatch("getDocs");
  },
  props: {},
  watch: {
    $route() {
      this.setBreadcumbs();
    }
  },
  mounted() {
    // this.adresses = window.location.pathname.split("/");
  },
  methods: {
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    getColor(method) {
      switch (method.toUpperCase()) {
        case "GET":
          return "primary";
        case "POST":
          return "warning";
        case "PUT":
          return "success";
        case "DELETE":
          return "red";
        case "OPTIONS":
          return "primary";
        case "PATCH":
          return "yellow";
        default:
          return "primary";
      }
    }
  }
};
</script>
<style scoped>
.v-tabs {
  border-bottom: 1px solid #e3e3e3;
}
.fixed-tabs-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
  z-index: 2;
}
</style>